<template>
  <div class="chain-face" @click="logMetadata">
    <img :src="svg" class="mx-auto" style="width: 100%;"/>
  </div>
</template>

<script>

import { ChainFaceGenerator } from '../js/generator';
import { ethers } from 'ethers';


export default {
  name: 'RandomChainFace',
  data() {
    return {
      svg: ''
    };
  },
  async mounted() {
    this.generate();
  },
  methods: {
    generate() {
      if (this._isDestroyed) {
        return;
      }

      const gen = new ChainFaceGenerator();
      const entropy = ethers.utils.randomBytes(64);
      const seed = ethers.BigNumber.from(entropy);

      const face = gen.assembleFace(seed);
      const golf = gen.calculateGolfScore(seed);

      let red, green, blue;

      if (golf > 56) {
        red = 255;
        green = 255;
        blue = 255 - Math.floor(golf - 56) * 6;
      } else {
        red = 255 - Math.floor(56 - golf) * 6;
        green = 255 - Math.floor(56 - golf) * 6;
        blue = 255;
      }

      const svg = `<svg xmlns='http://www.w3.org/2000/svg' width='400' height='400' style='background-color:rgba(${red},${green},${blue})'><text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' font-size='75px' fill=''>${face}</text></svg>`;

      this.svg = `data:image/svg+xml;utf8,${svg}`;

      setTimeout(this.generate.bind(this), 2000 + Math.random() * 10000)
    }
  }
};
</script>