<template>
  <div>
    <div v-if="!ethereum.account" class="text-lg">
      Connect your wallet to continue.
    </div>
    <div v-else>
      <div class="text-center mb-5 text-xl">
        <template v-if="claimed">
          You have already claimed.
        </template>
        <template v-else-if="ogAmount > 0 || wlAmount > 0">
          You can mint up to {{ ogAmount }} free and {{ wlAmount }} paid. Careful, you can only claim once.
        </template>
        <template v-else>
          You are not on the whitelist. The main sale will commence soon.
        </template>
      </div>
      <form @submit.prevent="submit" class="flex items-center justify-center" v-if="maxClaim > 0 && !claimed">
        <input v-model.number="quantity" type="number" min="1" :max="maxClaim"
               class="border border-gray-300 rounded-md p-4 mr-3 text-center text-2xl" style="width: 150px;"/>
        <button class="btn-mint text-xl md:text-3xl" :disabled="pending || !canAfford">
          Mint
        </button>
      </form>
    </div>
    <div class="text-center mt-3" v-if="maxClaim > 0 && !claimed" :class="{'text-red-500': !canAfford}">
      You are minting {{ quantity }} for {{ totalPriceInEth }} Ξ. Your balance is {{ balance }} Ξ.
    </div>
  </div>
</template>

<script>
import { Ethereum } from '../js/ethereum';
import ConnectButton from './ConnectButton';
import { notifyError, notifyTransaction } from '../js/notifications';
import { ethers } from 'ethers';

export default {
  name: 'ClaimForm',
  props: ['claim'],
  components: { ConnectButton },
  data () {
    return {
      pending: false,
      salePrice: ethers.BigNumber.from(0),
      balance: '0',
      canAfford: false,
      ogAmount: 0,
      wlAmount: 0,
      claimed: false,
      quantity: 0,
      ethereum: Ethereum.state
    };
  },
  watch: {
    'ethereum.account': {
      immediate: true,
      async handler (account) {
        const balance = await Ethereum.provider.getBalance(account);
        this.canAfford = balance.gte(this.totalPrice);
        this.balance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
      }
    },
    'claim': {
      immediate: true,
      async handler () {
        if (this.claim) {
          this.ogAmount = parseInt(this.claim.ogAmount, 16);
          this.wlAmount = parseInt(this.claim.wlAmount, 16);
          this.claimed = await Ethereum.contract.isClaimed(this.claim.index);
        } else {
          this.ogAmount = 0;
          this.wlAmount = 0;
        }

        this.quantity = this.ogAmount + this.wlAmount;
      }
    }
  },
  computed: {
    maxClaim () {
      return this.ogAmount + this.wlAmount;
    },
    totalPrice () {
      let amount = 0;
      if (this.quantity > this.ogAmount) {
        amount = this.quantity - this.ogAmount;
      }
      return this.salePrice.mul(amount);
    },
    totalPriceInEth () {
      return parseFloat(ethers.utils.formatEther(this.totalPrice)).toFixed(4);
    }
  },
  async mounted () {
    this.salePrice = await Ethereum.contract.salePrice();

    this.getData();
  },
  methods: {
    async getData () {
      if (this._isDestroyed) {
        return;
      }

      if (this.claim) {
        this.claimed = await Ethereum.contract.isClaimed(this.claim.index);

        if (!this.claimed) {
          const balance = await Ethereum.provider.getBalance(this.ethereum.account);
          this.canAfford = balance.gte(this.totalPrice);
          this.balance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        }
      }

      setTimeout(this.getData.bind(this), 5000);
    },
    async submit () {
      this.pending = true;

      try {
        let estimate = await Ethereum.contract.estimateGas.claim(this.claim.index, this.claim.ogAmount, this.claim.wlAmount, this.claim.proof, this.quantity, { value: this.totalPrice });
        let gasLimit = estimate.mul('12').div('10');
        const tx = await Ethereum.contract.claim(this.claim.index, this.claim.ogAmount, this.claim.wlAmount, this.claim.proof, this.quantity, {
          value: this.totalPrice,
          gasLimit: gasLimit
        });
        notifyTransaction(tx, 'Mint transaction sent.');
      } catch (e) {
        console.error(e.message);
        notifyError('Something went wrong.');
      }

      this.pending = false;
    }
  }
};
</script>