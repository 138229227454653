<template>
  <div class="min-h-screen bg-purple-pattern text-white flex flex-col">
    <Navigation/>
    <div class="container mx-auto py-10 flex-grow items-center justify-center flex">
      <div>
        <div v-if="!ethereum.connected">
          <div class="text-center text-2xl mt-10">
            Please connect your wallet to continue...
          </div>
          <div class="text-center mt-10 text-2xl">
            <ConnectButton/>
          </div>
        </div>
        <div v-else-if="loading">
          <Spinner/>
        </div>
        <div v-else-if="stage === 0">
          <div class="text-center">
            <div class="bg-white text-black p-10 xl:p-20 rounded-md shadow-md w-full m-auto">
              <div class="text-3xl my-5 font-pixel">
                Sale has Concluded
              </div>
              <div class="text-xl">
                {{ totalSupply }} were minted and are now only available on secondary markets.
              </div>
              <div class="text-center mt-10">
                <div class="mb-2">Try your luck on OpenSea:</div>
                <a href="https://opensea.io/collection/chainfaces-arena"> <img src="../assets/opensea-logo.png" style="width: 150px;" class="inline"/> </a>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mint-grid">
          <div class="mint-container bg-white text-black p-10 w-full flex items-center">
            <div class="w-full">
              <h1 class="text-2xl xl:text-4xl font-bold text-purple-black text-center font-pixel">
                <template v-if="stage === 1">
                  Claim your ChainFace
                </template>
                <template v-if="stage === 2">
                  Mint a ChainFace
                </template>
              </h1>
              <div class="text-center text-xl mt-5">
                <template v-if="stage === 1">
                  The whitelist sale is now open. If you have a whitelist spot, mint below.
                </template>
                <template v-else>
                  ChainFaces will be revealed after the sale concludes.
                </template>
              </div>
              <hr class="hidden 2xl:block mt-5"/>
              <div class="grid grid-cols-1 md:grid-cols-3 gap-4 w-full mt-5 mx-auto text-center items-justify">
                <div class="bg-gray-200 rounded-md py-5 px-3 flex flex-col justify-center">
                  <div class="text-base uppercase">Mint Price</div>
                  <div class="text-4xl font-bold">{{ salePrice }} Ξ</div>
                </div>
                <template v-if="stage === 1">
                  <div class="bg-gray-200 rounded-md py-5 px-3 flex flex-col justify-center">
                    <div class="text-base uppercase">Whitelist Sale Ends In</div>
                    <div class="text-4xl font-bold text-red-500">{{ whitelistEnds }}</div>
                    <div class="text-sm">Public sale will begin immediately after</div>
                  </div>
                </template>
                <template v-if="stage === 2">
                  <div class="bg-gray-200 rounded-md py-5 px-3 flex flex-col justify-center">
                    <div class="text-base uppercase">Time Left</div>
                    <div class="text-4xl font-bold">{{ timeRemaining }}</div>
                  </div>
                </template>
                <div class="bg-gray-200 rounded-md py-5 px-3 flex flex-col justify-center">
                  <div class="text-base uppercase">Supply</div>
                  <div class="text-4xl font-bold">{{ totalSupply }}</div>
                  <div class="text-sm text-purple-dark">Timed mint with max supply of {{ tokenLimit }}</div>
                </div>
              </div>
              <hr class="hidden 2xl:block mt-5"/>
              <div class="text-center mt-5">
                <MintForm v-if="stage === 2 && secondsRemaining > 0 && totalSupply < tokenLimit"/>
                <ClaimForm :claim="this.claim" v-else-if="stage === 1"/>
                <div v-else>Sale is now complete. Please wait for the reveal.</div>
              </div>
            </div>
          </div>
          <RandomChainFace v-for="i in 22" :key="i"/>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import { ethers } from 'ethers';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import { Ethereum } from '../js/ethereum.js';
import MintForm from '../components/MintForm';
import ConnectButton from '../components/ConnectButton';
import Spinner from '../components/Spinner';
import RandomChainFace from '../components/RandomChainFace';
import ClaimForm from '@/components/ClaimForm';

export default {
  name: 'Mint',
  components: { ClaimForm, RandomChainFace, Spinner, ConnectButton, MintForm, Footer, Navigation },
  data () {
    return {
      loading: true,
      totalSupply: 0,
      tokenLimit: 0,
      saleEnds: 0,
      stage: 0,
      claim: null,
      ogAmount: 0,
      wlAmount: 0,
      secondsRemaining: 0,
      timeRemaining: '--:--:--',
      whitelistEnds: '--:--:--',
      ethereum: Ethereum.state,
    };
  },
  mounted () {
    this.getData();

    this.$options.timer = setInterval(this.onTick.bind(this), 1000);
  },
  watch: {
    'ethereum.account': {
      immediate: true,
      handler () {
        this.calculateClaim();
      }
    }
  },
  methods: {
    async calculateClaim () {
      this.ogAmount = 0;
      this.wlAmount = 0;

      if (!this.ethereum.account) {
        return;
      }

      if (this.stage === 1) {
        let csAccount = ethers.utils.getAddress(this.ethereum.account);

        const MERKLE_TREE = await import(/* webpackChunkName: "merkle-tree" */'../../merkle-tree.json');
        this.claim = MERKLE_TREE.claims[csAccount];

        if (this.claim) {
          this.ogAmount = parseInt(this.claim.ogAmount.substr(2), 16);
          this.wlAmount = parseInt(this.claim.wlAmount.substr(2), 16);
        }
      }
    },
    async getData () {
      if (this._isDestroyed) {
        return;
      }

      if( Ethereum.contract ) {
        const [stage, totalSupply, tokenLimit, saleEnds, salePrice] = await Promise.all([
          Ethereum.contract.stage(),
          Ethereum.contract.totalSupply(),
          Ethereum.contract.tokenLimit(),
          Ethereum.contract.saleEnds(),
          Ethereum.contract.salePrice(),
        ]);

        this.secondsRemaining = this.saleEnds - Math.floor(new Date().getTime() / 1000);

        this.stage = stage;
        this.totalSupply = totalSupply.toNumber();
        this.tokenLimit = tokenLimit.toNumber();
        this.saleEnds = saleEnds.toNumber();
        this.salePrice = parseFloat(ethers.utils.formatEther(salePrice)).toFixed(4);

        this.calculateClaim();

        this.loading = false;
        this.onTick();
      }

      setTimeout(this.getData.bind(this), 5000);
    },
    onTick () {
      const secondsRemaining = this.saleEnds - Math.floor(new Date().getTime() / 1000);
      this.secondsRemaining = secondsRemaining;

      if (secondsRemaining <= 0) {
        this.timeRemaining = 'Sale Complete';
      } else {
        const hours = Math.floor(secondsRemaining / 60 / 60);
        const minutes = Math.floor((secondsRemaining - (hours * 60 * 60)) / 60);
        const seconds = secondsRemaining % 60;

        this.timeRemaining = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
      }

      const whitelistRemaining = 1641155400 -  Math.floor(new Date().getTime() / 1000);

      if (whitelistRemaining <= 0){
        this.whitelistEnds = 'Soon';
      } else {
        const hours = Math.floor(whitelistRemaining / 60 / 60);
        const minutes = Math.floor((whitelistRemaining - (hours * 60 * 60)) / 60);
        const seconds = whitelistRemaining % 60;

        this.whitelistEnds = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
      }
    }
  }
};
</script>

<style lang="scss">
.mint-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2px;
  border-radius: 50px;
  overflow: hidden;

  .mint-container {
    border-radius: 25px;
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 2;
    grid-row-end: 5;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(9, 9, 22, 0.2);
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    border-radius: 0;

    .mint-container {
      transform: scale(1);
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
    }

    .chain-face {
      display: none;
    }
  }

  .chain-face img {
    //@apply rounded-md shadow-md;
  }
}
</style>