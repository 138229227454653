const ethers = require('ethers');

const leftFaceArray = ["ლ","ᕦ","(ง","𐐋","ᖳ","Ƹ","ᛩ","⦃","{","⦗","〈","⧼","|","〘","〚","【","[","❪"];
const leftEyeArray = ["⚈","⚙","⊗","⋗"," ͡°","◈","◬","≻","᛫","⨕","★","Ͼ","ᗒ","◠","⊡","⊙","▸","˘","⦿","●"];
const mouthArray = ["෴","∪","ᨏ","᎔","᎑","⋏","⚇","_","۷","▾","ᨎ","ʖ","ܫ","໒","𐑒","⌴","‿","𐠑","⌒","◡","⥿","⩊"];
const rightEyeArray = ["⚈","⚙","⊗","⋖"," ͡°","◈","◬","≺","᛫","⨕","★","Ͽ","ᗕ","◠","⊡","⊙","◂","˘","⦿","●"];
const rightFaceArray = ["ლ","ᕤ",")ง","𐐙","ᖰ","Ʒ","ᚹ","⦄","}","⦘","〉","⧽","|","〙","〛","】","]","❫"];

const rarityArray = [0, 2, 5, 9, 14, 20, 27, 35, 44, 54, 65, 77, 90, 104, 119, 135, 152, 170, 189, 209, 230, 252];


class ChainFaceGenerator {
  getLeftFace(seed) {
    let raritySelector = seed.mod(189);

    let charSelector = 0;

    for (let i = 0; i < 18; i++) {
      if (raritySelector.gte(rarityArray[i])) {
        charSelector = i;
      }
    }

    return leftFaceArray[charSelector];
  }


  getLeftEye(seed) {
    let raritySelector = seed.mod(230);

    let charSelector = 0;

    for (let i = 0; i < 20; i++) {
      if (raritySelector.gte(rarityArray[i])) {
        charSelector = i;
      }
    }

    return leftEyeArray[charSelector];
  }


  getMouth(seed) {
    let raritySelector = seed.mod(275);

    let charSelector = 0;

    for (let i = 0; i < 22; i++) {
      if (raritySelector.gte(rarityArray[i])) {
        charSelector = i;
      }
    }

    return mouthArray[charSelector];
  }


  getRightEye(seed) {
    let raritySelector = ethers.BigNumber.from(ethers.utils.keccak256('0x' + seed.toHexString().substr(2).padStart(64, '0'))).mod(230);

    let charSelector = 0;

    for (let i = 0; i < 20; i++) {
      if (raritySelector.gte(rarityArray[i])) {
        charSelector = i;
      }
    }

    return rightEyeArray[charSelector];
  }


  getRightFace(seed) {
    let raritySelector = ethers.BigNumber.from(ethers.utils.keccak256('0x' + seed.toHexString().substr(2).padStart(64, '0'))).mod(189);

    let charSelector = 0;

    for (let i = 0; i < 18; i++) {
      if (raritySelector.gte(rarityArray[i])) {
        charSelector = i;
      }
    }

    return rightFaceArray[charSelector];
  }


  assembleFace(seed) {
    return this.getLeftFace(seed) + this.getLeftEye(seed) + this.getMouth(seed) + this.getRightEye(seed) + this.getRightFace(seed);
  }


  calculateGolfScore(seed) {
    let leftFaceRarity = seed.mod(189);
    let lefEyeRarity = seed.mod(230);
    let mouthRarity = seed.mod(275);
    let rightEyeRarity = ethers.BigNumber.from(ethers.utils.keccak256('0x' + seed.toHexString().substr(2).padStart(64, '0'))).mod(230);
    let rightFaceRarity = ethers.BigNumber.from(ethers.utils.keccak256('0x' + seed.toHexString().substr(2).padStart(64, '0'))).mod(189);

    let leftFaceGolf = 0;
    let leftEyeGolf = 0;
    let mouthGolf = 0;
    let rightEyeGolf = 0;
    let rightFaceGolf = 0;
    let i = 0;

    for (i = 0; i < 18; i++) {
      if (leftFaceRarity >= rarityArray[i]) {
        leftFaceGolf = i;
      }
    }
    for (i = 0; i < 20; i++) {
      if (lefEyeRarity >= rarityArray[i]) {
        leftEyeGolf = i;
      }
    }
    for (i = 0; i < 22; i++) {
      if (mouthRarity >= rarityArray[i]) {
        mouthGolf = i;
      }
    }
    for (i = 0; i < 20; i++) {
      if (rightEyeRarity >= rarityArray[i]) {
        rightEyeGolf = i;
      }
    }
    for (i = 0; i < 18; i++) {
      if (rightFaceRarity >= rarityArray[i]) {
        rightFaceGolf = i;
      }
    }

    return leftFaceGolf + leftEyeGolf + mouthGolf + rightEyeGolf + rightFaceGolf;
  }
}


module.exports = { ChainFaceGenerator };