<template>
  <div>
    <div v-if="!ethereum.account" class="text-lg">
      Connect your wallet to continue.
    </div>
    <div v-else>
      <form @submit.prevent="submit" class="flex items-center justify-center">
        <input v-model="quantity" type="number" min="1" :max="20"
               class="border border-gray-300 rounded-md p-4 mr-3 text-center text-2xl" style="width: 150px;"/>
        <button class="btn-mint text-xl md:text-3xl" :disabled="pending || !canAfford">
          Mint
        </button>
      </form>
    </div>
    <div class="text-center mt-3" :class="{'text-red-500': !canAfford}">
      You are minting {{ quantity }} for {{ totalPriceInEth }} Ξ. Your balance is {{ balance }} Ξ.
    </div>
  </div>
</template>

<script>
import { Ethereum } from '../js/ethereum';
import ConnectButton from './ConnectButton';
import { notifyError, notifyTransaction } from '../js/notifications';
import { ethers } from 'ethers';

export default {
  name: 'MintForm',
  components: { ConnectButton },
  data () {
    return {
      pending: false,
      salePrice: ethers.BigNumber.from(0),
      balance: '0',
      canAfford: false,
      quantity: 1,
      ethereum: Ethereum.state
    };
  },
  watch: {
    'ethereum.account': {
      immediate: true,
      async handler (account) {
        const balance = await Ethereum.provider.getBalance(account);
        this.canAfford = balance.gte(this.salePrice);
        this.balance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
      }
    },
  },
  computed: {
    totalPrice () {
      return this.salePrice.mul(this.quantity);
    },
    totalPriceInEth () {
      return parseFloat(ethers.utils.formatEther(this.totalPrice)).toFixed(4);
    }
  },
  async mounted () {
    this.salePrice = await Ethereum.contract.salePrice();
  },
  methods: {
    async submit () {
      this.pending = true;

      try {
        let estimate = await Ethereum.contract.estimateGas.createFace({ value: this.totalPrice });
        let gasLimit = estimate.mul('12').div('10');
        const tx = await Ethereum.contract.createFace({ value: this.totalPrice, gasLimit: gasLimit });
        notifyTransaction(tx, 'Mint transaction sent.');
      } catch (e) {
        console.error(e.message);
        notifyError('Something went wrong.');
      }

      this.pending = false;
    }
  }
};
</script>